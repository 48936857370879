import React, { PropsWithChildren, PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import SelectItemType from 'types/SelectItem';
import { AnyObject } from 'types/Common';

interface Props extends IntlProps {
  baseFields: {
    projectId: string | null;
    amount: string;
    currency: string | null;
    paymentMethodUrlAlias: SelectItemType | null;
  };
  dictionaries: {
    currencies: SelectItemType[];
    projects: SelectItemType[];
    methods: SelectItemType[];
  };
  onChange: (field: any, value: any) => void;
  validationErrors: AnyObject;
  isProjectFetch: boolean;
  isClarification: boolean;
  isPreFilled: boolean;
}

class SingleRequest extends PureComponent<PropsWithChildren<Props>> {
  render() {
    const {
      onChange,
      baseFields,
      dictionaries,
      isProjectFetch,
      validationErrors,
      isPreFilled,
      isClarification,
      getTranslate,
    } = this.props;

    const { currencies, projects, methods } = dictionaries;
    const { amount, currency, projectId, paymentMethodUrlAlias } = baseFields;

    return (
      <div className='ui-form-fields__container ui-form-fields__content'>
        <div className='ui-form-fields__text ui-form-fields__container_header'>
          {getTranslate('payouts.request.single.infoText')}
        </div>
        <div className='ui-form-fields__inner'>
          <div className='ui-form-fields__col ui-form-fields__col_general'>
            <div className='ui-form-fields__col-title'>
              {getTranslate('payouts.request.single.generalInfo.label')}
            </div>
            <>
              <CustomSelect
                id='general.projectId'
                label={getTranslate('payouts.request.single.projectLabel')}
                placeholder={getTranslate(
                  'payouts.request.single.projectPlaceholder'
                )}
                value={projectId}
                customClass='ui-form-fields__item utils-form-item'
                onChange={(item) => onChange('projectId', item)}
                options={projects}
                isSearchable
                isDisabled={isPreFilled || isClarification}
                containerSelector='.ui-form-fields__content'
                modern
              />
              {this.isMethods() ? (
                <>
                  <div className='ui-form-fields__item'>
                    <CustomSelect
                      id='method'
                      isDisabled={
                        (!isProjectFetch && !projectId) ||
                        this.isPreFilledCard() ||
                        isClarification
                      }
                      isLoading={isProjectFetch}
                      label={getTranslate('payouts.request.single.methodLabel')}
                      value={paymentMethodUrlAlias}
                      placeholder={getTranslate(
                        'payouts.request.single.methodPlaceholder'
                      )}
                      customClass='ui-form-fields__field ui-select_payment-method'
                      onChange={(item) =>
                        onChange('paymentMethodUrlAlias', item)
                      }
                      options={methods}
                      containerSelector='.ui-form-fields__content'
                      modern
                    />
                  </div>
                  <div className='ui-form-fields__item utils-flex'>
                    <div className='ui-form-fields__item'>
                      <InputAmount
                        id='payment.amount'
                        label={getTranslate(
                          'payouts.request.single.amountLabel'
                        )}
                        placeholder={getTranslate(
                          'payouts.request.single.amountPlaceholder'
                        )}
                        value={amount}
                        error={
                          validationErrors?.['payment.amount'] ||
                          validationErrors?.['amount']
                        }
                        customClass='ui-form-fields__field'
                        onChange={(event) =>
                          onChange('amount', event.target.value)
                        }
                        disabled={isClarification}
                        modern
                      />
                    </div>

                    <div className='ui-form-fields__item'>
                      <CustomSelect
                        id='payment.currency'
                        label={getTranslate('payouts.request.single.currency')}
                        placeholder={getTranslate(
                          'payouts.request.single.currency.placeholder'
                        )}
                        value={this.checkSelectOption(currency)}
                        customClass='ui-form-fields__field utils-select-menu-width'
                        onChange={(value) => onChange('currency', value?.value)}
                        options={currencies}
                        isDisabled={
                          this.isPreFilledCard() ||
                          isClarification ||
                          !currencies
                        }
                        isSearchable
                        isLoading={!currencies}
                        containerSelector='.ui-form-fields__content'
                        tooltip={getTranslate(
                          'select.currency.favorite.tooltip'
                        )}
                        modern
                      />
                    </div>
                  </div>
                </>
              ) : (
                <p>
                  {getTranslate('payouts.request.single.noAvailableProjects')}
                </p>
              )}
            </>
          </div>
          <div
            className={classNames(
              'ui-form-fields__col ui-form-fields__col_additional'
            )}>
            <div className='ui-form-fields__col-title'>
              {getTranslate('payouts.request.single.additionalInfo.label')}
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  checkSelectOption = (param) => {
    const selectedCurrency = this.props.dictionaries.currencies.find(
      (currency) => currency.value === param
    );

    return selectedCurrency ? selectedCurrency : null;
  };

  isFirstStepFulfilled = () => {
    const { baseFields, isProjectFetch } = this.props;
    const { amount, currency, projectId } = baseFields;

    return !(
      currency &&
      parseFloat(amount) > 0 &&
      projectId &&
      !isProjectFetch
    );
  };

  isPreFilledCard = () => {
    const { baseFields, isPreFilled } = this.props;
    const { paymentMethodUrlAlias } = baseFields;
    return paymentMethodUrlAlias?.value === 'card' && isPreFilled;
  };

  isMethods = () => {
    const { dictionaries, baseFields, isProjectFetch } = this.props;
    const { projectId } = baseFields;
    const { methods } = dictionaries;

    return (
      !projectId ||
      !!(projectId && isProjectFetch) ||
      !!(projectId && methods.length)
    );
  };
}

export default addTranslation(SingleRequest);
